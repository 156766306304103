<template lang="pug">
.NoDate.Container(:style="isAtril ? 'color: #545454; width: 100%; height: calc(100vh - 112.06px); min-height: 600px;' : 'margin: auto; width: 100%; font-size: 1.3rem;'")
  Progress3
  .Content.mt-0.box.pb-0(:style="parentList.length < 1 && padding ? 'padding:'+padding+'px' : ''")
    div(v-if="parentList.length > 0" v-html="pageSubTramiteLabel")
    div.innerHTML(v-else v-html="pageTramiteLabel")
    h3.fonterH3.fonterH3NoDate.mb-1.mx-auto(v-if="!pageTramiteLabel && !pageSubTramiteLabel") Cuéntanos, ¿qué necesitas?
    p.fonterNoDate.textHero.m-0.mx-auto.d-block.mb-3(v-if="!pageTramiteLabel && !pageSubTramiteLabel") Selecciona la opción que deseas consultar
    .pt-1.buttons-dk.d-flex.text-start.justify-content-center(:style="isAtril ? '' : 'margin: 30px auto;'").mx-auto
      template(v-for="act in actionToShow")
        button.arrowButton.p-4.w-100.my-3.text-center.button-without-styles.mx-3(
          :style="act.disabled ? 'opacity:0.7':''"
          v-if="!!!act.hidden"
          :key="act.actionCode"
          :disabled="!!act.disabled"
          :class="selectedThing === act && 'selectedThing'"
          block
          @click="() => onSelectedCheckbox(act)"
        ).d-flex
          //- div(v-html="selectIcon(act.label)")
          img(:src="act.button_icon" :alt="act.label")
          span(v-html="act.label").my-4.w-100.text-center.d-block.fontButton
          .position-relative.container(v-if="!act.disabled")
            input(
              type="checkbox"
              :id="act.actionCode"
              disabled
            ).checkBoxArrow
            .checkmark
    .pb-2
      button(
        :class="!selectedThing ? 'disabled' : 'active'"
        :disabled="!selectedThing"
        @click="calculateAction(selectedThing)"
      ).text-center.buttonService.containerForm.mt-3
        | Continuar
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Progress3 from "./components/Progress3";
import Back from "mdi-vue/ArrowLeftBold.vue";
import moment from "moment";

export default {
  name: "NoDate",

  components: {
    Header,
    Back,
    Progress3
  },

  data: () => ({
    parentList: [],
    actionToShow: [],
    selectedThing: null,
    interval01: null
  }),

  created() {
    this.interval01 = setInterval(() => {
      this.setTimer(true);
    }, 100);
    // Change view to messages
    if (this.messages && this.messages.length > 0) {
      return this.goToView({ view: "Messages" });
    }

    this.setLocalActions(this.menu);
    if (this.isAtril) this.$store.dispatch("setTimer3");
  },

  destroyed() {
    if (this.interval01) clearInterval(this.interval01);
  },

  computed: {
    ...mapState({
      menu: state => state.virtualrowFSFB.menu,
      currentSite: state => state.virtualrowFSFB.currentSite,
      isAtril: state => state.virtualrowFSFB.isAtril,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      secondColorBlue: state => state.virtualrowFSFB.secondColorBlue,
      currentUser: state => state.virtualrowFSFB.currentUser,
      sites: state => state.virtualrowFSFB.sites
    }),

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    },

    siteName() {
      return this.currentSite?.name;
    },

    messages() {
      return this.sites[0]?.customAreas[this.$route.query.area]?.messages;
    },

    pageTramiteLabel() {
      return this.sites[0]?.customAreas[this.$route.query.area]
        ?.pageTramiteLabel;
    },

    pageSubTramiteLabel() {
      return this.sites[0]?.customAreas[this.$route.query.area]
        ?.pageSubTramiteLabel;
    },

    padding() {
      return this.sites[0]?.customAreas[this.$route.query.area]?.padding;
    }
  },

  methods: {
    ...mapActions({
      goBack: "virtualrowFSFB/goBack",
      setAction: "virtualrowFSFB/setAction",
      turnCall: "virtualrowFSFB/turnCall",
      removeTimer: "removeTimer",
      setTimer3: "setTimer3",
      setTimer: "setTimer",
      goToView: "virtualrowFSFB/goToView"
    }),

    setLocalActions(actions) {
      actions = JSON.parse(JSON.stringify(actions));
      for (let i = 0; i < actions.length; i++) {
        if (actions[i].hidden && typeof actions[i].hidden === "object") {
          actions[i].hidden =
            (actions[i].hidden.mobile && !this.isAtril) ||
            (actions[i].hidden.atril && this.isAtril);
        }
      }
      this.actionToShow = actions;
    },

    async calculateAction(act) {
      if (this.isAtril) this.$store.dispatch("setTimer3");

      // check user age
      let diff = moment.duration(
        moment().diff(moment(this.currentUser?.fecha_nacimiento))
      );
      act.userAge = diff.asYears();

      // if (this.isAtril) await this.removeTimer();
      // if (this.isAtril) await this.setTimer3();

      if (act?.children) {
        this.parentList.push(this.actionToShow);
        return this.setLocalActions(act.children);
      }

      await this.setAction(act);
    },

    onSelectedCheckbox(act) {
      if (this.isAtril) {
        this.$store.dispatch("setTimer3");
      }

      this.selectedThing = act;
      const $checkBox = document.getElementById(act.actionCode);
      const allCheckBoxes = document.querySelectorAll(".checkBoxArrow");

      allCheckBoxes.forEach($checkboxParam => ($checkboxParam.checked = ""));

      $checkBox.checked = "true";
    },

    selectIcon(labelIcon) {
      const icons = {
        "Paciente con cita programada": `
          <svg class="svgIcon" width="86" height="91" viewBox="0 0 86 91" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M70.9716 56.9641C70.9759 62.502 69.3371 67.9166 66.2627 72.5226C63.1882 77.1286 58.8164 80.719 53.7005 82.8392C48.5846 84.9595 42.9547 85.5142 37.5234 84.4333C32.0921 83.3524 27.1035 80.6844 23.1892 76.7671C19.2749 72.8497 16.6108 67.8591 15.5341 62.4269C14.4574 56.9948 15.0165 51.3653 17.1407 46.2511C19.2649 41.1368 22.8586 36.7678 27.467 33.6969C32.0754 30.6261 37.4913 28.9915 43.0292 29C50.4419 29.0058 57.5492 31.9545 62.7888 37.1982C68.0284 42.4419 70.9716 49.5514 70.9716 56.9641Z" fill="#89D4F4"/>
            <path d="M50.0645 17.9856C50.0693 21.1531 49.1345 24.2509 47.3782 26.887C45.6219 29.523 43.1232 31.5789 40.1982 32.7944C37.2731 34.0099 34.0533 34.3304 30.9461 33.7154C27.8388 33.1004 24.9838 31.5775 22.7423 29.3395C20.5008 27.1014 18.9736 24.2487 18.3539 21.1424C17.7341 18.0361 18.0498 14.8158 19.2608 11.8889C20.4718 8.96204 22.5239 6.46014 25.1572 4.69986C27.7906 2.93957 30.8869 2 34.0545 2C38.2964 2 42.3649 3.68338 45.3667 6.68057C48.3684 9.67776 50.058 13.7437 50.0645 17.9856V17.9856Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.0122 65.9678C1.89416 61.6966 2.63349 57.445 4.18649 53.4643C5.7395 49.4837 8.07467 45.8547 11.054 42.7919C14.0334 39.7291 17.5964 37.2945 21.5327 35.6322C25.4689 33.9698 29.6984 33.1133 33.9713 33.1133C38.2441 33.1133 42.4737 33.9698 46.4099 35.6322C50.3461 37.2945 53.9092 39.7291 56.8885 42.7919C59.8679 45.8547 62.2031 49.4837 63.7561 53.4643C65.3091 57.445 66.0484 61.6966 65.9304 65.9678H2.0122Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M55.6108 48.5293V53.9515" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M73.7236 48.5293V53.9515" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M50.4303 50.8145H78.8188C80.1703 50.8145 81.4664 51.3513 82.4221 52.307C83.3777 53.2626 83.9146 54.5587 83.9146 55.9102V83.2484C83.9146 84.6037 83.3762 85.9034 82.4179 86.8617C81.4596 87.82 80.1599 88.3584 78.8046 88.3584H50.4161C49.0646 88.3584 47.7685 87.8215 46.8128 86.8659C45.8572 85.9102 45.3203 84.6141 45.3203 83.2626V55.9102C45.3203 55.2398 45.4526 54.5761 45.7096 53.9569C45.9665 53.3377 46.3432 52.7753 46.8178 52.302C47.2925 51.8286 47.856 51.4535 48.4758 51.1983C49.0957 50.943 49.7599 50.8126 50.4303 50.8145V50.8145Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45.3345 65.7051H83.9997" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        "Paciente con autorización previa": `
          <svg class="svgIcon" width="110" height="85" viewBox="0 0 110 85" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M90.9716 45.9641C90.9759 51.502 89.3371 56.9166 86.2627 61.5226C83.1882 66.1286 78.8164 69.719 73.7005 71.8392C68.5846 73.9595 62.9547 74.5142 57.5234 73.4333C52.0921 72.3524 47.1035 69.6844 43.1892 65.7671C39.2749 61.8497 36.6108 56.8591 35.5341 51.4269C34.4574 45.9948 35.0165 40.3653 37.1407 35.2511C39.2649 30.1368 42.8586 25.7678 47.467 22.6969C52.0754 19.6261 57.4913 17.9915 63.0292 18C70.4419 18.0058 77.5492 20.9545 82.7888 26.1982C88.0284 31.4419 90.9716 38.5514 90.9716 45.9641Z" fill="#B5DC68"/>
            <path d="M49.7225 17.8717C49.7273 21.0166 48.7991 24.0923 47.0554 26.7096C45.3116 29.3268 42.8307 31.368 39.9265 32.5749C37.0223 33.7817 33.8254 34.1 30.7403 33.4894C27.6552 32.8787 24.8206 31.3667 22.5951 29.1446C20.3696 26.9225 18.8532 24.0902 18.2379 21.006C17.6226 17.9218 17.9359 14.7244 19.1384 11.8184C20.3408 8.91241 22.3782 6.42835 24.9928 4.68061C27.6073 2.93287 30.6816 2 33.8266 2C38.0383 2 42.0778 3.67139 45.0581 6.64721C48.0385 9.62304 49.7161 13.66 49.7225 17.8717V17.8717Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.01212 65.5119C1.89491 61.2711 2.62897 57.0499 4.17091 53.0976C5.71285 49.1453 8.03137 45.5422 10.9895 42.5012C13.9476 39.4602 17.4853 37.0431 21.3934 35.3925C25.3016 33.742 29.501 32.8916 33.7434 32.8916C37.9858 32.8916 42.1852 33.742 46.0934 35.3925C50.0015 37.0431 53.5392 39.4602 56.4973 42.5012C59.4554 45.5422 61.774 49.1453 63.3159 53.0976C64.8578 57.0499 65.5919 61.2711 65.4747 65.5119H2.01212Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M107.182 61.1335C107.173 65.3031 105.929 69.3766 103.607 72.8394C101.284 76.3022 97.9872 78.9988 94.1326 80.5886C90.278 82.1784 86.0387 82.59 81.9502 81.7714C77.8618 80.9528 74.1078 78.9407 71.1624 75.9894C68.217 73.0381 66.2125 69.28 65.4022 65.1899C64.5918 61.0999 65.0119 56.8614 66.6095 53.01C68.2071 49.1586 70.9104 45.8671 74.3778 43.5515C77.8453 41.2359 81.9213 40 86.0909 40C88.8641 40 91.6102 40.5469 94.1718 41.6095C96.7335 42.6721 99.0605 44.2294 101.02 46.1924C102.979 48.1554 104.531 50.4855 105.589 53.0493C106.646 55.6131 107.187 58.3602 107.182 61.1335V61.1335Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M79.0078 59.7705L84.6424 65.4052" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M93.1594 56.876L84.6436 65.406" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        "Asignación de citas ": `
          <svg class="svgIcon" width="86" height="74" viewBox="0 0 86 74" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M85.9716 45.9641C85.9759 51.502 84.3371 56.9166 81.2627 61.5226C78.1882 66.1286 73.8164 69.719 68.7005 71.8392C63.5846 73.9595 57.9547 74.5142 52.5234 73.4333C47.0921 72.3524 42.1035 69.6844 38.1892 65.7671C34.2749 61.8497 31.6108 56.8591 30.5341 51.4269C29.4574 45.9948 30.0165 40.3653 32.1407 35.2511C34.2649 30.1368 37.8586 25.7678 42.467 22.6969C47.0754 19.6261 52.4913 17.9915 58.0292 18C65.4419 18.0058 72.5492 20.9545 77.7888 26.1982C83.0284 31.4419 85.9716 38.5514 85.9716 45.9641Z" fill="#E16E5F"/>
            <path d="M16.6328 2V9.71016" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M42.3867 2V9.71016" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.26613 5.25003H49.6334C51.5552 5.25003 53.3982 6.01343 54.7571 7.37231C56.116 8.73118 56.8794 10.5742 56.8794 12.496V51.3697C56.8794 53.2968 56.1138 55.1449 54.7512 56.5076C53.3885 57.8703 51.5403 58.6358 49.6132 58.6358H9.24593C7.32418 58.6358 5.48115 57.8724 4.12228 56.5135C2.7634 55.1546 2 53.3116 2 51.3899V12.496C2 11.5427 2.18809 10.5988 2.5535 9.71839C2.9189 8.83796 3.45444 8.03827 4.12942 7.36516C4.80441 6.69205 5.60558 6.15873 6.48703 5.79578C7.36847 5.43283 8.31288 5.24737 9.26613 5.25003V5.25003Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.02051 18.874H57.0008" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        "Entrega de resultados": `
          <svg class="svgIcon" width="86" height="97" viewBox="0 0 86 97" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M85.9716 68.9641C85.9759 74.502 84.3371 79.9166 81.2627 84.5226C78.1882 89.1286 73.8164 92.719 68.7005 94.8392C63.5846 96.9595 57.9547 97.5142 52.5234 96.4333C47.0921 95.3524 42.1035 92.6844 38.1892 88.7671C34.2749 84.8497 31.6108 79.8591 30.5341 74.4269C29.4574 68.9948 30.0165 63.3653 32.1407 58.2511C34.2649 53.1368 37.8586 48.7678 42.467 45.6969C47.0754 42.6261 52.4913 40.9915 58.0292 41C65.4419 41.0058 72.5492 43.9545 77.7888 49.1982C83.0284 54.4419 85.9716 61.5514 85.9716 68.9641Z" fill="#6BBBC5"/>
            <path d="M13.3111 15.3896H10.3138C8.11259 15.3896 6.00127 16.2626 4.44276 17.817C2.88425 19.3714 2.00579 21.4804 2 23.6816V68.7073C2.00579 70.9084 2.88425 73.0175 4.44276 74.5719C6.00127 76.1263 8.11259 76.9992 10.3138 76.9992H49.4323C50.5268 77.0079 51.6121 76.7998 52.6257 76.387C53.6393 75.9741 54.5611 75.3647 55.3381 74.5938C56.115 73.823 56.7317 72.906 57.1525 71.8957C57.5733 70.8854 57.7899 69.8017 57.7899 68.7073V23.6816C57.7899 21.4824 56.9163 19.3733 55.3612 17.8183C53.8062 16.2632 51.6971 15.3896 49.498 15.3896H46.5444" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M37.0267 9.50399H43.7871C44.15 9.50108 44.5098 9.5704 44.8455 9.7079C45.1813 9.84541 45.4863 10.0484 45.7429 10.3049C45.9995 10.5615 46.2024 10.8665 46.3399 11.2023C46.4774 11.5381 46.5468 11.8979 46.5438 12.2607V18.496C46.5467 18.8598 46.4776 19.2207 46.3403 19.5577C46.2031 19.8946 46.0005 20.2011 45.7442 20.4594C45.488 20.7177 45.1831 20.9228 44.8472 21.0627C44.5113 21.2026 44.151 21.2746 43.7871 21.2746H16.0891C15.3522 21.2746 14.6455 20.9818 14.1244 20.4608C13.6033 19.9397 13.3105 19.2329 13.3105 18.496V12.2607C13.3105 11.8968 13.3826 11.5365 13.5225 11.2006C13.6624 10.8647 13.8674 10.5599 14.1257 10.3036C14.384 10.0473 14.6905 9.84474 15.0275 9.7075C15.3645 9.57026 15.7253 9.50111 16.0891 9.50399H22.8277" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.8283 9.50474C22.7747 8.53926 22.9186 7.57296 23.2512 6.66498C23.5837 5.75701 24.0979 4.92641 24.7624 4.22396C25.427 3.52151 26.2278 2.962 27.1159 2.57961C28.0041 2.19721 28.9609 2 29.9278 2C30.8948 2 31.8516 2.19721 32.7398 2.57961C33.6279 2.962 34.4287 3.52151 35.0933 4.22396C35.7578 4.92641 36.272 5.75701 36.6046 6.66498C36.9372 7.57296 37.081 8.53926 37.0274 9.50474" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M43.6767 38.5598C42.8619 37.7776 41.7761 37.3408 40.6466 37.3408C39.517 37.3408 38.4313 37.7776 37.6164 38.5598L27.2023 49.0177L23.483 45.2983C23.122 44.7621 22.6467 44.3126 22.0913 43.982C21.5358 43.6514 20.9141 43.4479 20.2706 43.3863C19.6272 43.3246 18.9781 43.4063 18.37 43.6254C17.7618 43.8445 17.2098 44.1957 16.7536 44.6535C16.2973 45.1114 15.9482 45.6647 15.7313 46.2736C15.5144 46.8825 15.435 47.5319 15.499 48.1751C15.563 48.8183 15.7687 49.4393 16.1013 49.9935C16.4339 50.5478 16.8852 51.0215 17.4227 51.3805L24.1612 58.1191C24.9771 58.9078 26.0675 59.3486 27.2023 59.3486C28.3371 59.3486 29.4275 58.9078 30.2434 58.1191L43.6767 44.6857C44.4785 43.8678 44.9276 42.7681 44.9276 41.6228C44.9276 40.4774 44.4785 39.3777 43.6767 38.5598Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `,
        "Solicitud de medio de contraste (TAC)": `
          <svg class="svgIcon" width="92" height="87" viewBox="0 0 92 87" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M91.9716 58.9641C91.9759 64.502 90.3371 69.9166 87.2627 74.5226C84.1882 79.1286 79.8164 82.719 74.7005 84.8392C69.5846 86.9595 63.9547 87.5142 58.5234 86.4333C53.0921 85.3524 48.1035 82.6844 44.1892 78.7671C40.2749 74.8497 37.6108 69.8591 36.5341 64.4269C35.4574 58.9948 36.0165 53.3653 38.1407 48.2511C40.2649 43.1368 43.8586 38.7678 48.467 35.6969C53.0754 32.6261 58.4913 30.9915 64.0292 31C71.4419 31.0058 78.5492 33.9545 83.7888 39.1982C89.0284 44.4419 91.9716 51.5514 91.9716 58.9641Z" fill="#F2E462"/>
            <path d="M54.3602 68.9547C53.9435 68.9622 53.5301 68.8775 53.1471 68.706L35.5348 60.8396L17.9443 68.706C17.4528 68.9286 16.9138 69.0124 16.3814 68.9489C15.8491 68.8854 15.3421 68.6769 14.9114 68.3444C14.4761 68.0204 14.1307 67.5822 13.9101 67.0741C13.6894 66.566 13.6014 66.006 13.6549 65.451L15.3664 45.559L2.78001 30.5044C2.42209 30.0877 2.17556 29.5801 2.06528 29.0325C1.955 28.4849 1.98484 27.9167 2.15176 27.385C2.31356 26.8675 2.5963 26.4001 2.97451 26.0252C3.35272 25.6503 3.8145 25.3796 4.31808 25.2375L22.9918 20.7166L32.8053 3.55975C33.0788 3.08343 33.4662 2.68932 33.9295 2.41593C34.3927 2.14255 34.916 1.99925 35.4482 2.00003V2.00003C35.9836 1.99755 36.5106 2.13996 36.9776 2.41331C37.4446 2.68667 37.8357 3.08165 38.1128 3.55975L47.9262 20.7166L66.5783 25.2375C67.1028 25.3567 67.5883 25.617 67.9866 25.9926C68.3848 26.3682 68.6819 26.846 68.8483 27.3785C69.0148 27.911 69.0449 28.4797 68.9355 29.0281C68.826 29.5764 68.581 30.0854 68.2247 30.5044L55.725 45.6042L57.3931 64.9763C57.4276 65.2464 57.4276 65.52 57.3931 65.79C57.3931 66.2135 57.3128 66.6328 57.1569 67.0237C57.0009 67.4146 56.7724 67.7695 56.4844 68.0678C56.1965 68.3662 55.8548 68.6021 55.4791 68.7621C55.1034 68.922 54.701 69.0029 54.2952 68.9999L54.3602 68.9547Z" stroke="#001698" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        `
      };

      return icons[labelIcon] || "";
    },

    BackCalculed() {
      if (this.isAtril) this.$store.dispatch("setTimer3");
      console.log("parentList");
      console.log(this.parentList);
      if (this.parentList.length) {
        this.setLocalActions(this.parentList.pop());
      } else this.goBack();
    }
  }
};
</script>

<style scoped>
.atril_text {
  font-size: 2rem;
}
.atril-mini {
  font-size: 1.3rem;
}
.NoDate {
  position: relative;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.NoDate > div {
  display: table-cell;
  vertical-align: middle;
}

.box-dk {
  max-width: 365px;
  margin: 1.4rem auto;
  text-align: center;
  display: block;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.3rem;
  width: 100%;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

.button {
  background-color: #0057a0;
  color: #fff;
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.buttons-dk {
  width: 100%;
  min-height: 86px;
  max-width: 1800px;
  flex-wrap: wrap;
}

svg[fill="currentColor"] {
  width: 50px;
  height: 50px;
  /* fill: gray; */
}

button svg[fill="currentColor"] {
  /* fill: white; */
  width: auto;
  height: auto;
}

.dk-pos-dk {
  position: absolute;
  top: -30%;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.h1 {
  font-size: 2.8rem;
}

.fonterH3NoDate {
  width: 627px;
}

.arrowButton {
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 25px;
  color: #454d55;
  background: #ffffff;
  box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.25);
  border-radius: 7px;
  height: 255px;
  width: 300px !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.arrowButton img {
  max-width: 90px;
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.checkBoxArrow {
  width: 26px;
  height: 26px;
  border: 2px solid #788591;
}

.selectedThing {
  font-family: "Gilroy-Semibold";
  color: #009dac;
  border: 3px solid #18a2af;
}

div.Container {
  display: block;
  text-align: center;
  max-width: 100%;
}

div.Content {
  display: block;
  margin: auto;
}

/* Hide the browser's default checkbox */
.checkBoxArrow {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.container {
  width: 29px;
  height: 29px;
}

.container:hover input ~ .checkmark {
  background-color: #ffffff;
}

.container input:checked ~ .checkmark {
  background: url(../../assets/checkbox.svg);
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 30px;
  width: 30px;
  background-color: #ffffff;
  border: 2px solid #788591;
  border-radius: 5px;
}

.fonterNoDate {
  max-width: 584px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.box {
  padding: 124px 0;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin: 0 1rem;
}

.box.box2 {
  padding: 0;
}

.box-tape {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(40, 54, 69, 0.301);
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.text-start {
  text-align: start;
}

.fontButton {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 24px;
  text-align: center;
  color: #788591;
}

.buttonService {
  font-family: "Gilroy-Semibold";
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.boxText {
  position: absolute;
  bottom: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  max-width: fit-content;
}

.welcomeText {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  font-size: 48px;
  line-height: 55px;
  color: #ffffff;
}

.descText,
.consultasText {
  font-size: 24px;
  color: #ffffff;
}

.logoFSFBTransparent {
  position: absolute;
  right: 0;
  bottom: 2px;
  z-index: 2;
}

.copyrightText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.consultasText {
  max-width: 497px;
}

.buttonService:disabled {
  background-color: #f2f2f2;
  color: #dedede;
  filter: none;
}

.containerForm {
  max-width: 476px;
  margin: auto;
  display: block;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -10%;
  }
}
</style>
