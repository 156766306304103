<template lang="pug">
.Quote.Container(@click="validationAction" style="height: calc(100vh - 96.38px); min-height: 600px; width: 100%; align-items: flex-start;")
  .Content
    h3.textHero.w-100.mt-5.pt-3 !Bienvenido{{ (fullname && fullname.trim() !== "undefined") ? `, ${fullname}` : "" }}!
    p.fonterH3.m-0.mx-auto.d-block(style="font-size: 40px;padding: 24px;") Selecciona la cita a la que vienes
    div(:class="isAtril ? 'cards-dk' : 'cards'" v-if="citasEnCM && citasEnCM.length")
      .big_box.mt-4(
        :style="isAtril ? 'position: relative; min-height: 160px; min-width: 600px;' : ''"
      ).d-flex.ml-4
        .dk-overlay.pb-4.mr-4(
          v-for="cita in citasEnCM"
          @click="selectAppointment(cita)"
          :class="availableTime(cita) ? selectedSchedule === cita && 'selectedSchedule' : 'disableSchedule' "
        )
          h5.titleCard.pl-4.ml-2.mb-2.pt-4 {{cita.service.detail}}
          h6.tramiteCard.pl-4.ml-2.mb-2
            strong.fontWeightCard Trámite:
            span.pl-3 {{cita.attention.detail}}
          p.confirmCard.pl-4.ml-2.mb-5 № de confirmación: {{cita.number_form}}
          .m-0.dateCard.d-flex.align-items-center.justify-content-between.mx-auto
            .d-flex.align-items-center.ml-4
              svg(width='46' height='46' viewbox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg').svgIconDate
                path(d='M18.062 10.4856V13.6596' stroke='#89D4F4' stroke-width='2.02572' stroke-linecap='round' stroke-linejoin='round')
                path(d='M28.6636 10.4856V13.6596' stroke='#89D4F4' stroke-width='2.02572' stroke-linecap='round' stroke-linejoin='round')
                path(d='M15.0298 11.8235H31.6474C32.4386 11.8235 33.1973 12.1378 33.7567 12.6972C34.3161 13.2566 34.6303 14.0153 34.6303 14.8064V30.8092C34.6303 31.6025 34.3152 32.3633 33.7542 32.9243C33.1933 33.4852 32.4324 33.8004 31.6391 33.8004H15.0214C14.2303 33.8004 13.4716 33.4861 12.9122 32.9267C12.3528 32.3673 12.0386 31.6086 12.0386 30.8175V14.8064C12.0386 14.414 12.116 14.0254 12.2664 13.663C12.4169 13.3005 12.6373 12.9713 12.9152 12.6942C13.193 12.4171 13.5229 12.1976 13.8857 12.0482C14.2486 11.8987 14.6373 11.8224 15.0298 11.8235V11.8235Z' stroke='#89D4F4' stroke-width='2.02572' stroke-linecap='round' stroke-linejoin='round')
                path(d='M12.0483 17.4316H34.6816' stroke='#89D4F4' stroke-width='2.02572' stroke-linecap='round' stroke-linejoin='round')
              p.dayCard {{citaFecha(cita.date_appoinment)}}
            .d-flex.align-items-center.mr-5
              svg(width='46' height='46' viewbox='0 0 46 46' fill='none' xmlns='http://www.w3.org/2000/svg').svgIconDate
                path(d='M22.8294 33.8006C29.0519 33.8006 34.0963 28.7563 34.0963 22.5337C34.0963 16.3112 29.0519 11.2668 22.8294 11.2668C16.6069 11.2668 11.5625 16.3112 11.5625 22.5337C11.5625 28.7563 16.6069 33.8006 22.8294 33.8006Z' stroke='#89D4F4' stroke-width='2.02572' stroke-linecap='round' stroke-linejoin='round')
                path(d='M22.8291 22.5339V16.4307' stroke='#89D4F4' stroke-width='2.02572' stroke-linecap='round' stroke-linejoin='round')
                path(d='M22.8291 22.5337H26.6774' stroke='#89D4F4' stroke-width='2.02572' stroke-linecap='round' stroke-linejoin='round')
              p.dayCard {{citaHora(cita.date_appoinment + ' ' + cita.hour_appoinment)}}
    svg(width='35' height='36' viewbox='0 0 35 36' fill='none' xmlns='http://www.w3.org/2000/svg').svgIconHelpInfo
      path(d='M17.5 2C8.66345 2 1.5 9.16344 1.5 18C1.5 26.8366 8.66344 34 17.5 34C26.3366 34 33.5 26.8366 33.5 18C33.5 9.16344 26.3366 2 17.5 2Z' stroke='#4B5CB7' stroke-width='2.5')
      path(d='M17.4995 26L17.4995 16.4L17.4995 26ZM17.4995 10.8L17.4995 10L17.4995 10.8Z' fill='white')
      path(d='M17.4995 10.8L17.4995 10M17.4995 26L17.4995 16.4L17.4995 26Z' stroke='#4B5CB7' stroke-width='2.5' stroke-linecap='round')
    p.fontBlue.firstFont Recuerda tomar tu turno antes de
    p.fontBlue la hora de la cita
    .d-block.text-center.d-flex.mt-4.pt-3.align-items-center(style="flex-direction: column; margin: auto; max-width: 370px;")
      button(
        v-if="(!citasEnCM || (citasEnCM && citasEnCM.length < 1) || (!citasFueraCM || (citasFueraCM && citasFueraCM.length < 1)))"
        type="button"
        @click="selectCita(selectedSchedule)"
        :disabled="!Object.keys(selectedSchedule).length"
      ).ml-2.mb-3.text-center.buttonService
        span Continuar con cita elegida
      button(
        v-else
        type="button"
        @click="selectCita(selectedSchedule)"
        :disabled="!Object.keys(selectedSchedule).length"
      ).ml-2.mb-3.text-center.buttonService
        span Continuar con cita elegida
      button(
        type="button"
        @click="noSelect"
      ).ml-2.text-center.outlineService.button-without-styles
        span Ver otros servicios
    //- p(v-if="citasFueraCM.length" :style="isAtril ? 'margin-bottom: 1rem; margin-top: 1rem;' : 'margin-top: 0.5rem;'")
    //-   small.text-center.mt-1.dk-link(@click="showModalOthers = true") Ver citas en otros centros
    //- b-modal(
    //-   v-model="showModalOthers",
    //-   hide-footer
    //- )
    //-   h6.text-center(style="color: gray;" :style="isAtril && 'font-size: 1.4rem;'") Estas citas son de otros Centros Médicos
    //-   .cards(style="padding-bottom: 100px")
    //-     .big_box(v-for="cita in citasFueraCM", :key="cita.idCita").mb-1
    //-       .dk-overlay
    //-         h5.titleCard Cita {{ cita.idCita }}.
    //-         h6.tramiteCard Nombre del centro médico: {{ cita.NombreCentroMedico }}
    //-         p.confirmCard Especialidad: {{ cita.Especialidad }}
    //-         p.m-0.dateCard: small(style="font-weight: bold !important;") {{ cita.Fecha }}
            //- h6.tramiteCard Nombre del centro médico: {{ cita.NombreCentroMedico }}
            //- p.tramiteCard Especialidad: {{ cita.Especialidad }}
            //- p.m-0 Fecha: {{ cita.Fecha }}
            //- p.m-0: small(style="font-weight: bold !important;") {{ cita.Fecha }}
  .buttons.Footer(:style="colorSecondaryComputed")
    .d-flex.justify-content-between
      b-button(
        variant="bluecolmedica"
        class="text-center"
        size="sm"
        type="button"
        :style="colorComputed"
        @click="goBack"
      )
        Back(size="24px")
        span(class="text-sizing-12") Atrás
</template>

<script>
import { mapState, mapActions } from "vuex";

import infoSvgIcon from "@/assets/infoSvgIconSmall.svg";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import AlertCircle from "mdi-vue/AlertCircle";
import moment from "moment";
moment.locale('es');

export default {
  name: "Quote",

  components: {
    Header,
    Back,
    Continue,
    AlertCircle
  },

  data() {
    return {
      showModal: false,
      showModalOthers: false,
      infoSvgIcon,
      selectedSchedule: {},
      beforeMessage: "",
      afterMessage: ""
      // infoDir: "",
      // infoName: ""
    };
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      currentSite: state => state.virtualrowFSFB.currentSite,
      citas: state => state.virtualrowFSFB.citas,
      isAtril: state => state.virtualrowFSFB.isAtril,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      seocndColorBlue: state => state.virtualrowFSFB.seocndColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      currentUser: state => state.virtualrowFSFB.currentUser,
      sites: state => state.virtualrowFSFB.sites,
      customArea: state => state.virtualrowFSFB.customArea,
      env: state => state.env
    }),

    fullname() {
      return this.currentUser
        ? this.currentUser.nombre + ' ' +
            (this.currentUser.apellido || '') + ' ' +
            (this.currentUser.segundo_apellido || '')
        : "";
    },

    // computedText() {
    //   return this.citasEnCM.length >= 3
    //     ? `
    //     Para tus siguientes citas programadas, recuerda tomar turno antes de la hora de la cita
    //   `
    //     : this.citasEnCM.length == 2
    //     ? ` Para tu siguiente cita programada, recuerda tomar turno antes de la hora de la cita`
    //     : "";
    // },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    principalText() {
      return this.citasEnCM.length
        ? this.citasEnCM.length === 1
          ? `Esta es la atención que tienes programada hoy en el Centro Médico ${this
              .currentSite?.name ??
              ""}.<span style="display: block; margin-top: 0.5rem;">Selecciona una o continúa sin cita</span>`
          : `Estas son las citas que tienes programadas para hoy en el Centro Médico ${this
              .currentSite?.name ??
              ""}. <span style="display: block; margin-top: 0.5rem;">Selecciona una o continúa sin cita</span>`
        : `No tienes citas programadas para hoy en el Centro Médico ${this
            .currentSite?.name ?? ""}`;
    },

    colorComputed() {
      return `white-space: no-wrap; background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    citasEnCM() {
      return this.citas;
    },

    citasFueraCM() {
      return this.citas.filter(cita => !cita.EnCentroMedico);
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      setCitaCM: "virtualrowFSFB/setCitaCM",
      goBack: "virtualrowFSFB/goBack",
      goToView: "virtualrowFSFB/goToView",
      removeTimer: "removeTimer",
      setTimer: "setTimer"
    }),
    validationAction() {
      if (this.isAtril) this.$store.dispatch("setTimer");
    },
    async selectCita(cita) {
      if (this.isAtril) await this.removeTimer();
      await this.setCitaCM(cita);
      if (this.isAtril) await this.setTimer();
    },
    citaHora(hour) {
      //TODO: Por ahora esto no const dateCita = new Date(this.citasEnCM[0].Fecha).getHours();
      //TODO: Esta es forma de solo poner hora const splitFecha = this.citasEnCM[0].Fecha.split(":");
      // const result = `${splitFecha[0].slice(-2)}:${splitFecha[1]}`;
      return moment(hour).format('hh:mm a');
    },

    citaFecha(date){
      return moment(date).format('MMMM Do YYYY')
    },

    selectAppointment(cita){
      if(this.availableTime(cita))
        this.selectedSchedule = cita;
        sessionStorage.setItem("appointment", cita.number_form);
        sessionStorage.setItem("fecha_cita", moment(`${cita.date_appoinment} ${cita.hour_appoinment}`).unix());
        sessionStorage.setItem("info_cita", JSON.stringify(cita));
    },

    availableTime(cita) {
      let minutes = moment().diff(moment(`${cita.date_appoinment} ${cita.hour_appoinment}`), 'minutes');
      minutes = minutes > 0 ? parseInt(minutes) : parseInt(minutes) * -1;
      var isafter = moment().unix() - moment(`${cita.date_appoinment} ${cita.hour_appoinment}`).unix();
      if(isafter > 0){
        return minutes <= this.env.VUE_APP_APPOINTMENT_TIME_AVAILABLE_AFTER;
      } else return minutes <= this.env.VUE_APP_APPOINTMENT_TIME_AVAILABLE_BEFORE;
    },

    noSelect(){
      sessionStorage.removeItem("info_cita");
      sessionStorage.removeItem("fecha_cita");
      sessionStorage.removeItem("appointment");
      this.goToView({ view: 'NoDate' })
    }
  }
};
</script>

<style scoped>
.Quote {
  height: calc(100vh - 70px);
  overflow: auto;
  display: table;
}

.Quote > div {
  display: table-cell;
  vertical-align: middle;
}

.box-dk {
  max-width: 488px;
  margin: 1.4rem auto;
  text-align: center;
  margin-top: 2rem;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.icon-alert {
  color: #4b5c6b;
  margin: 15px auto;
  display: block;
  max-width: 50px;
  text-align: center;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 14px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  color: #fff;
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

.buttons-dk {
  max-width: 296px;
  display: block;
  margin: 3rem auto;
  text-align: center;
  min-height: 20rem;
}

.span svg {
  width: 50px;
  height: 50px;
  fill: gray;
}

.big_box {
  position: relative;
  scroll-snap-align: center;
  overflow-x: auto;
  max-width: 100vw;
}

.big_box::-webkit-scrollbar {
  display: none;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.dk-link {
  cursor: pointer;
  outline: none;
  border: none;
  appearance: none;
  font-weight: 400;
  font-size: 14.3743px;
  line-height: 24px;
  /* identical to box height, or 164% */

  text-align: center;
  text-decoration-line: underline;

  /* TEXT/Títulos, sub títulos, body. */

  color: #283645;
}

.dk-link:hover {
  text-decoration: underline;
}

.cards-dk {
  display: flex;
  justify-content: center;
  margin: auto;
  /* max-width: 834px;
  overflow-x: scroll;
  overscroll-behavior-x: contain;
  scroll-snap-type: x proximity;
  position: relative;
  min-height: 290px; */
}

.dk-pos-dk {
  position: absolute;
  top: -90%;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.dk-overlay {
  width: 584px;
  transition: 0.5s all;
  text-align: start;
  border: none;
  background: #f9f9f9;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(21, 21, 21, 0.02),
    0px 8px 12px rgba(21, 21, 21, 0.08);
  border-radius: 21.6077px;
}

.titleCard {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #001698;
}

.tramiteCard {
  font-family: "Gilroy-SemiBold";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 27px;
  color: #283645;
}

.confirmCard {
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 28px;
  color: #788591;
}

.dateCard {
  width: 520px;
  height: 64px;
  border-radius: 17.5563px;
  background: #daf1fb86;
}

.fontWeightCard {
  font-family: "Gilroy-Semibold";
}

.dk-overlay .specialPG {
  font-style: normal;
  font-weight: 400;
  font-size: 16.4277px;
  line-height: 24px;
  color: #081e3d;
  margin: 0;
}

.buttonService {
  font-family: "Gilroy-Semibold";
  width: 504px;
  padding: 1.6rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.buttonService:disabled {
  background-color: #f2f2f2;
  color: #dedede;
  filter: none;
}

.outlineService {
  width: 504px;
  padding: 1.5rem 2rem;
  color: #009dac;
  border: 2px solid #009dac;
  border-radius: 20px;
}

.dayCard {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 24px;
  color: #788591;
}

.boxQuote {
  width: 100%;
  max-width: 432.46px;
}

.disableSchedule {
  opacity: 0.5 !important;
  background: #e4e4e4 !important;
}

.selectedSchedule {
  background: #12b7fe;
}

.selectedSchedule .confirmCard,
.selectedSchedule .titleCard,
.selectedSchedule .tramiteCard {
  color: #ffffff;
}

.selectedSchedule .dateCard {
  background-color: #ffffff;
}

.selectedSchedule .dayCard {
  color: #788591;
}

.selectedSchedule .svgIconDate path {
  stroke: #788591;
}

.fontBlue {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #4b5cb7;
}

.firstFont {
  margin-top: 19px;
}

.svgIconHelpInfo {
  margin-top: 48px;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@media screen and (max-width: 420px) {
  .pos_dk {
    position: relative;
    width: 100%;
    right: 0;
    bottom: 0;
  }
}
</style>
