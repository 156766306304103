<template lang="pug">
div
  .Progress(style="position: fixed;")
    .container.p-5
      .progressCounter(:data-percentage="percent" @click="toHome" style="cursor: pointer;")
        span.progressCounter-left
          span.progressCounter-bar
        span.progressCounter-right
          span.progressCounter-bar
        .progressCounter-value
          .mt--1
            | {{seconds}}
</template>

<script>
/* eslint-disable prettier/prettier */
  import { mapState, mapActions } from "vuex";
  
  export default {
    name: "Progress",
  
    data() {
      return {
        percent: 100,
        seconds: 90,
        timeout: null,
        canceled: false
      };
    },
  
    created() {
      if (this.restart) {
        this.$store.dispatch("setTimer");
      }
      this.seconds = 90;
      this.timerFunction();
    },
  
    beforeDestroy() {
      clearInterval(this.timeout);
    },
  
    computed: {
      ...mapState({
        generalTimeoutCounter: state => state.generalTimeoutCounter,
        view: state => state.virtualrowFSFB.view,
        restart: state => state.restart,
        removeTimeout: state => state.removeTimeout,
        primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
        threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor
      }),
    },
  
    watch: {
      generalTimeoutCounter() {
        if (this.removeTimeout) return this.percent = 0;
        this.percent = 0;
        setTimeout(() => {
          this.seconds = 90;
          this.percent = 100;
        }, 100);
      },
    },
  
    methods: {
      ...mapActions({
        goToView: "virtualrowFSFB/goToView",
        clearMessagePays:  "virtualrowFSFB/clearMessagePays"
      }),
      timerFunction() {
        const self = this;
        clearInterval(self.timeout);
        this.timeout = setInterval(function() {
          if (self.seconds <= 0) {
            clearInterval(self.timeout);
            self.$emit("endTimer")

            // canceled actions
            if(!self.canceled){
              self.canceled = true;
              self.seconds = 5;
              self.clearMessagePays();
              return self.timerFunction();
            }
            
            self.toHome();

          }
          self.seconds -= 1;
        }, 1000);
      },
      toHome() {
        this.goToView({ view: "QR" });
      },
      stopProgress(){
        clearInterval(this.timeout);
      }
    }
  };
  </script>
  
<style lang="scss">
$borderWidth: 3.5px;
$animationTime: 45s;
$border-color-default: rgba(242, 244, 248, 0.788);
$border-color-progressbar: #ffffff;
$size: 55px;
$howManySteps: 100; //this needs to be even.

.progressCounter {
  width: $size !important;
  height: $size !important;
  line-height: $size;
  background: none;
  margin: 1px auto;
  box-shadow: none;
  position: relative;
  position: fixed;
  top: 18.41px;
  left: 50%;
  transform: translateX(-50%);
  // background-color: #9199c5 !important;
  .mt--1 {
    margin-top: -3px;
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: $borderWidth solid $border-color-default;
    position: absolute;
    top: 0;
    left: 0;
  }
  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }
  .progressCounter-left {
    left: 0;
  }
  .progressCounter-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: $borderWidth;
    border-style: solid;
    position: absolute;
    top: 0;
    border-color: $border-color-progressbar;
  }
  .progressCounter-left .progressCounter-bar {
    left: 100%;
    border-top-right-radius: ($size/2);
    border-bottom-right-radius: ($size/2);
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    //animation: loading-2 1.5s linear forwards 1.8s;
  }
  .progressCounter-right {
    right: 0;
    .progressCounter-bar {
      left: -100%;
      border-top-left-radius: ($size/2);
      border-bottom-left-radius: ($size/2);
      border-right: 0;
      -webkit-transform-origin: center right;
      transform-origin: center right;
      //animation: loading-1 1.8s linear forwards;
    }
  }
  .progressCounter-value {
    display: flex;
    border-radius: 50%;
    font-size: 1rem;
    text-align: center;
    line-height: 20px;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    font-weight: 300;
    span {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.colorHouse {
  fill: #ffffff;
  color: #ffffff;
}

.colorHouseDanger {
  fill: rgb(240, 64, 64);
  color: rgb(240, 64, 64);
}

/* This for loop creates the necessary css animation names
Due to the split circle of progress-left and progress right, we must use the animations on each side.
*/
@for $i from 1 through $howManySteps {
  $stepName: ($i * (100 / $howManySteps));

  //animation only the left side if below 50%
  @if $i <= ($howManySteps/2) {
    .progressCounter[data-percentage="#{$stepName}"] {
      .progressCounter-right .progressCounter-bar {
        animation: loading-#{$i} $animationTime linear forwards;
      }
      .progressCounter-left .progressCounter-bar {
        animation: 0;
      }
    }
  }
  //animation only the right side if above 50%
  @if $i > ($howManySteps/2) {
    .progressCounter[data-percentage="#{$stepName}"] {
      .progressCounter-right .progressCounter-bar {
        // eslint-disable-next-line prettier/prettier
        animation: loading-#{($howManySteps/2)}$animationTime
          linear
          forwards; //set the animation to longest animation
      }
      .progressCounter-left .progressCounter-bar {
        animation: loading-#{$i -
          ($howManySteps/2)}
          $animationTime
          linear
          forwards
          $animationTime;
      }
    }
  }
}

//animation
@for $i from 1 through ($howManySteps/2) {
  $degrees: (180/ ($howManySteps/2));
  $degrees: ($degrees * $i);
  @keyframes loading-#{$i} {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate($degrees);
      transform: rotate(#{$degrees}deg);
    }
  }
}
</style>
