<template lang="pug">
.Normativity.Container(@click="actionActive" :style="isAtril ? 'margin: auto; width: 100%; display: block; overflow: hidden; max-width: 1254px; height: calc(100vh - 96.38px);' : 'width: 100%; font-size: 1.3rem;'")
  .Content.mx-auto.d-block
    article.Article(:style="!isAtril ? 'color: #545454;' : 'color: #545454; margin-top: 40px; max-width: 584px;'")
      div.box-dk.mt-4.mb-3.pt-3.text-center
        h3(style="width: 545px").fonterH3.mx-auto.d-block.fonterAtt Prioridad en la atención
        p.textHero.m-0.mx-auto.d-block.pb-4 ¿Qué tipo de atención deseas?
    .d-flex.justify-content-center
      .card-box-white.p-4
        p.text-center.text-success-local.titleCard.mx-auto.mt-4
          strong.mx-1 Con turno preferencial
        .d-flex.align-items-center.justify-content-center.height-container.mb-5
          img(src="@/assets/embarazo.svg" alt="embarazo" style="margin-top: -1px;").embarazo.mr-3
          img(src="@/assets/silla.svg" alt="silla" style="margin-top: 2px;").silla.mx-4
          img(src="@/assets/ball2.svg" alt="ball2" style="margin-top: 33px;").ball2
        div(style="padding-bottom: 20px; margin-top: 1.3rem;")
          button(style="display: block; margin: auto;" size="sm" type="button" @click="turnCall(true)").buttonOutlined.text-center
            span Tomar turno preferencial
      .card-box-white.p-4.ml-4
        p.text-center.text-success-local.titleCard.mx-auto.mt-4
          strong.mx-1 Sin turno preferencial
        .desc-blue.mx-auto.d-flex.justify-content-center.align-items-center.mb-5
          div
            p.text-desc-blue Protejamos el bienestar de todos,
            p.text-desc-blue tus acciones valen más
        button.text-center.buttonGreen(type="button" @click="turnCall(false)").mt-4
          span Continuar sin turno preferencial
  .buttons.Footer(:style="colorSecondaryComputed")
    .d-flex.justify-content-between
      b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goBack" :style="isAtril ? `font-size: 1.5rem !important; ${colorComputed}` : colorComputed")
        Back(size="24px")
        span(class="text-sizing-12" :style="isAtril ? 'font-size: 1.5rem !important;' : ''") Atrás
</template>

<script>
import { mapActions, mapState } from "vuex";

import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import WheelBarrow from "mdi-vue/WheelchairAccessibility";

export default {
  name: "Normativity",

  components: {
    Header,
    Back,
    WheelBarrow
  },

  created() {
    if (this.isAtril) this.$store.dispatch("setTimer");
  },

  computed: {
    ...mapState({
      previousView: state => state.virtualrowFSFB.previousView,
      isAtril: state => state.virtualrowFSFB.isAtril,
      currentSite: state => state.virtualrowFSFB.currentSite,
      primaryColorBlue: state => state.virtualrowFSFB.primaryColorBlue,
      threeColorBlueColor: state => state.virtualrowFSFB.threeColorBlueColor,
      secondColorBlue: state => state.virtualrowFSFB.secondColorBlue
    }),

    siteName() {
      return this.currentSite?.name;
    },

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      goBack: "virtualrowFSFB/goBack",
      turnCall: "virtualrowFSFB/turnCall"
    }),

    dontCallToEmergency() {
      this.priority = false;
    },

    actionActive() {
      if (this.isAtril) this.$store.dispatch("setTimer");
    }
  }
};
</script>

<style>
.Normativity {
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
}

.Normativity > div {
  display: table-cell;
  vertical-align: middle;
}

.Article {
  max-width: 300px;
  display: block;
  margin: 20px auto;
  text-align: center;
}

.titleCard {
  width: 415px;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #001698;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  background: rgb(223, 230, 237);
  margin: auto;
  z-index: 1035;
  padding: 1rem;
}

.derecho {
  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;

  color: #081e3d;
}

.blue-dk {
  color: #00599d;
}

.image {
  width: 100%;
  object-fit: cover;
}

.dk-pos-dk.isAtril {
  top: -90%;
}
.dk-pos-dk {
  position: absolute;
  left: 0px;
  transition: 1s;
  right: 0px;
}

.card-box-white {
  width: 584px;
  height: 424px;

  filter: drop-shadow(0px 4.33129px 8.66258px rgba(21, 21, 21, 0.08));
  background: #f9f9f9;
  border-radius: 23.2678px;
}

.listUnderlined {
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 32px;

  text-align: center;

  color: #283645;
}

.circleEstimated {
  background: none;
  display: flex !important;
  flex-direction: column;
  width: 93px;
  color: #788591;
  border-radius: 50%;
  height: 93px;
  align-items: center;
  justify-content: center;
  border: 3px solid gray;
  margin-top: 16px;
  margin-left: 72px;
  font-weight: bold;
}

.circleEstimated small {
  margin-top: -10px;
  font-weight: bold;
  font-size: 17px;
}

.buttonOutlined {
  font-family: "Gilroy-Semibold";
  width: 504px;
  height: 88px;
  padding: 1rem 2rem;
  border: 2.16564px solid #009dac;
  box-sizing: border-box;
  border-radius: 17.3252px;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 25px;
  text-align: center;
  color: #009dac;
  background: none;
}

.buttonGreen {
  font-family: "Gilroy-Semibold";
  width: 504px;
  height: 88px;

  font-size: 28px;
  line-height: 25px;
}

.height-container {
  height: 125px;
}

.height-container img {
  height: fit-content;
}

h3.fonterAtt {
  width: 545px;
}

div.Container {
  display: block;
}

.desc-blue {
  width: 453px;
  height: 99px;
  background: #daf1fba8;
  border-radius: 17.5563px;
}

.text-desc-blue {
  font-family: "Gilroy-Semibold";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #000f65;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}

@media screen and (max-width: 420px) {
  .image {
    width: 50%;
  }
}
</style>
