<template lang="pug">
.ExistentUser.d-flex.justify-content-center
  .banderaSvg
  .Content
    .CardWhite
      .box-dk.mb-0.pb-4.px-5.text-center.d-flex.justify-content-start.container-text-top
        svg(width='81' height='78' viewbox='0 0 81 78' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M80.1181 49.8162C80.1224 55.354 78.4836 60.7686 75.4091 65.3747C72.3347 69.9807 67.9629 73.5711 62.847 75.6913C57.7311 77.8115 52.1012 78.3663 46.6699 77.2854C41.2386 76.2045 36.25 73.5365 32.3357 69.6191C28.4214 65.7018 25.7572 60.7111 24.6805 55.279C23.6038 49.8468 24.163 44.2173 26.2872 39.1031C28.4114 33.9889 32.0051 29.6198 36.6135 26.549C41.2219 23.4781 46.6378 21.8435 52.1756 21.8521C59.5884 21.8578 66.6957 24.8066 71.9353 30.0503C77.1749 35.2939 80.1181 42.4034 80.1181 49.8162Z' fill='#B5DC68')
          path(d='M46.3497 16.75C46.3542 19.6727 45.4916 22.531 43.871 24.9633C42.2505 27.3956 39.9449 29.2925 37.246 30.4141C34.5471 31.5356 31.5761 31.8314 28.7091 31.2639C25.842 30.6965 23.2077 29.2913 21.1394 27.2262C19.0712 25.1611 17.662 22.529 17.0902 19.6628C16.5184 16.7966 16.8096 13.8252 17.927 11.1245C19.0445 8.42389 20.9379 6.11539 23.3677 4.49117C25.7975 2.86694 28.6545 2 31.5772 2C35.4912 2 39.2452 3.55326 42.015 6.31878C44.7847 9.0843 46.3437 12.836 46.3497 16.75V16.75Z' stroke='#001698' stroke-width='4' stroke-linecap='round' stroke-linejoin='round')
          path(d='M2.01126 61.023C1.90234 57.0819 2.58452 53.159 4.01748 49.486C5.45045 45.813 7.60512 42.4646 10.3542 39.6385C13.1032 36.8124 16.3909 34.5661 20.0228 33.0322C23.6548 31.4983 27.5574 30.708 31.5 30.708C35.4426 30.708 39.3452 31.4983 42.9772 33.0322C46.6091 34.5661 49.8968 36.8124 52.6458 39.6385C55.3949 42.4646 57.5496 45.813 58.9825 49.486C60.4155 53.159 61.0977 57.0819 60.9887 61.023H2.01126Z' stroke='#001698' stroke-width='4' stroke-linecap='round' stroke-linejoin='round')
        h4.textHero.mb-2.m-0.ml-3.boldLabel.d-flex.align-items-center Confírmanos tus datos
      .px-5
        p
          strong.boldLabel.boldName Nombre:
          span.ml-1.textFonter.pl-3 {{ fullname }}
        p.text-2
          strong.boldLabel.boldDoc Documento:
          span.ml-1.textFonter.pl-3 {{ docType }}
        p.text-3
          strong.boldLabel.boldNumber Número:
          span.ml-1.textFonter.pl-3 {{ docNumber }}
    .mx-auto.text-center
      button(
        @click="onGoUserView"
      ).text-center.buttonService.d-block.mx-auto
        span
          | Confirmar
      button(
        @click="onGoToCurrentUser"
      ).text-center.button-without-styles.outlinedServiceButton.mr-4
        span
          | No son mis datos
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ExistentUser",

  computed: {
    ...mapState({
      isAtril: state => state.virtualrowFSFB.isAtril,
      currentUser: state => state.virtualrowFSFB.currentUser
    }),

    fullname() {
      return this.currentUser
        ? this.currentUser.names + ' ' +
            (this.currentUser.last_name || '') + ' ' +
            (this.currentUser.mother_last_name || '')
        : "";
    },

    docType() {
      return this.currentUser
        ? this.currentUser.tdoc
          ? this.currentUser.tdoc.detail
          : "N/R"
        : "";
    },

    docNumber() {
      return this.currentUser ? this.currentUser.doc : "";
    }
  },

  methods: {
    ...mapActions({
      goToView: "virtualrowFSFB/goToView"
    }),

    onGoToCurrentUser() {
      this.goToView({ view: "Home" });
    },

    onGoUserView() {
      if (this.isAtril) this.$store.dispatch("setTimer");
      this.goToView({ view: "NoDate" });
    }
  }
};
</script>

<style scoped>
.ExistentUser {
  height: calc(100vh - 112.06px);
}

.textFonter {
  font-weight: 800;
  font-size: 26px;
  line-height: 32px;
  color: #788591;
}

.boldLabel {
  font-weight: 800;
  color: #788591;
}

.textFonter {
  font-weight: 400;
}

.buttonService {
  font-family: "Gilroy-Semibold";
  width: 100%;
  max-width: 497px;
  padding: 1.5rem 2rem;
  margin-top: 48px;
  margin-bottom: 45px;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.buttonService:disabled {
  background-color: #788591;
}

.buttonService.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.outlinedServiceButton {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 25px;
  color: #009dac;
}

.CardWhite {
  margin-top: 192px;
  width: 735px;
  height: 296px;
  border-radius: 20px;
  filter: drop-shadow(0px 5.77255px 11.5451px rgba(21, 21, 21, 0.08));
  background: #ffffff;
}

.textHero {
  font-family: "Gilroy";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 55px;
  color: #788591;
}

.boldNumber {
  margin-left: 48px;
}

.boldName {
  margin-left: 46px;
}

.text-2,
.text-3 {
  margin-top: -10px;
}

.container-text-top {
  padding-top: 56px;
}
</style>
