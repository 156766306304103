<template lang="pug">
.Payment
  h3.fonterH3.w-100.mt-5.pt-3.mx-auto.d-block.text-center !Hola!
  p.textHero.SelectText.m-0.mx-auto.d-block.mt-4 Selecciona la forma en que deseas pagar tu cita
  .cards.d-flex.justify-content-between.align-item-center.mx-auto.mt-5(:class="!isPaymentDatafono && 'isPaymentDatafono'")
    .cardPayment
      p.text-card Escanea el código QR que se encuentra en pantalla para realizar tu pago de forma OnLine
      .position-relative(v-show="!qrLoading")
        img(src="@/assets/atrilFSFB/border-green.png" alt="border-green").border-green.mx-auto.d-block.mt-5.pt-2
        #qrCode.qrCode
      .position-relative(v-show="qrLoading")
        Loading.border-green.mx-auto.d-block.mt-5.pt-2
    .cardPayment(v-if="isPaymentDatafono")
      p.text-card-others Realiza tu pago por medio de nuestro módulo de datafono
      img(src="@/assets/atrilFSFB/payment_datafono.png" alt="payment_datafono").payment_datafono
      button(@click="openTerminalPay").text-center.buttonService
        span
          | Continuar
    .cardPayment
      p.text-card-others Realiza tu pago con uno de nuestros asesores
      img(src="@/assets/atrilFSFB/user.png" alt="user").payment_datafono
      button(@click="setAppointment").text-center.buttonService
        span
          | Continuar

  //- Modals
  b-modal(id="pays-modal-virtualrowFSFB" hide-footer hide-header size="xl" centered no-close-on-backdrop)
    .ContainerModal
      .d-block.text-center.mx-auto
        p.mt-4.mb-0.fonterCurrentUser Realizarás el pago de $ {{ currentAppointment.cost_appoinment }} a través del datáfono
        p.my-3.fonterCurrentUser Para la cita de {{ currentAppointment.service.detail }}
        p(v-show="failPay").mb-3.text-danger.fonterCurrentUser La transacción ha sido declinada.
        p(v-show="sucessPay").mb-3.text-success.fonterCurrentUser El pago se realizó con éxito.
        p(v-show="cancelPay").mb-3.text-danger.fonterCurrentUser El pago se ha cancelado.
        p.pb-5.fonterCurrentUser(v-show="!failPay && !sucessPay && !cancelPay && !inPayment") ¿Qué deseas hacer?
        p.pb-5.fonterCurrentUser(v-show="cancelPay") Cerrando...
        p.pb-5.fonterCurrentUser(v-show="sucessPay") Redireccionando...
        p.pb-5.fonterCurrentUser(v-show="!failPay && pending") Tarjeta leída, esperando respuesta del datáfono...
        p.pb-5.fonterCurrentUser(v-show="inPayment && (!failPay && !sucessPay && !cancelPay)") Realizando el pago...
        p.pb-5.fonterCurrentUser(v-show="inPayment && (!failPay && !sucessPay && !cancelPay)")  Por favor, <b>presiona la tecla verde del datáfono y siga las instrucciones</b>.
      .d-flex.containerBox
        button(v-show="!failPay && !sucessPay && !cancelPay" @click="closeModal").buttonServiceModal.canceledbtn
          span Cancelar
        //- button(v-show="failPay" @click="closeModal").buttonServiceModal.canceledbtn
        //-   span Cerrando...
        button(v-show="!failPay && !sucessPay && !cancelPay" @click="makePay").buttonServiceModal.ml-3
          span(v-if="!loading && !failPay") Pagar
          .position-relative(v-else)
            ProgressCounter(@endTimer="cancelPayAction" ref="ProgressCounter")
</template>

<script>
import { mapActions, mapState } from "vuex";
import QRCode from "easyqrcodejs";
import ProgressCounter from "./components/ProgressCounter.vue";
import Loading from "../components/Spinner.vue";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

export default {
  name: "Payment",

  components: {
    ProgressCounter,
    Loading
  },

  data() {
    return {
      isPaymentDatafono: false,
      interval: null,
      intervalFetch: null,
      interPays: null,
      failPay: false,
      cancelPay: false,
      sucessPay: false,
      pending: false,
      loading: false,
      respFromPays: {},
      inPayment: false,
      idTransaction: null,
      terminal: null
    };
  },

  computed: {
    ...mapState({
      currentUser: state => state.virtualrowFSFB.currentUser,
      currentAppointment: state => state.virtualrowFSFB.currentAppointment,
      currentSite: state => state.virtualrowFSFB.currentSite,
      qrLoading: state => state.virtualrowFSFB.qrLoading,
      sedeCode: state => state.virtualrowFSFB.sedeCode,
      sites: state => state.virtualrowFSFB.sites,
      customArea: state => state.virtualrowFSFB.customArea,
      isAtril: state => state.virtualrowFSFB.isAtril,
      view: state => state.virtualrowFSFB.view,
      respPayments:  state => state.virtualrowFSFB.respPayments,
      env: state => state.env,
      dataPayment:  state => state.virtualrowFSFB.dataPayment,
    }),

    letterCapPos() {
      return Number(this.env.VUE_APP_LETTERCAP_POS) || 40;
    },
  },

  async mounted() {
    let uuid = uuidv4();

    // send data for pay
    let info = await this.sendMessageForPay({
      company_id: this.currentSite.branch_id,
      user_document: this.currentUser.doc,
      id_cita_pago: this.currentAppointment.number_form,
      uuid: uuid,
      area: this.$route.query.area,
      docType: this.currentUser.tdoc.code,
      sedeCode: this.sedeCode
    });

    setTimeout(() => {
      this.$store.dispatch("removeTimer");
      this.removeTimer();
    }, 1000);

    // Pays with QR
    info = Object.keys(info.data).length > 0 ? info.data : {};

    new QRCode(document.getElementById("qrCode"), {
      text: `${location.origin}/#/mobile_fsfb/1?area=${this.$route.query.area}&id_cita_pago=${info.id_cita_pago}&row_id=${info.id}`
    });

    let message;
    this.intervalFetch = setInterval(async () => {
      message = await this.checkMessageStatus({
        id_cita_pago: this.currentAppointment.number_form
      });
      if (message.response) {
        if (message.data.length > 0) {
          message = message.data.find(d => d.uuid == uuid);
          if (message.status == "Leído") {
            if (this.intervalFetch) {
              clearInterval(this.intervalFetch);
              this.intervalFetch = null;
            }
            this.goToView({ view: "Home" });
            return;
          }
        }
      }
    }, 4000);

    this.interval = setInterval(() => {
      this.$store.dispatch("setTimer");
    }, 1000);

    // show datafono icon
    let queues = this.sites.find(s => s.code == this.sedeCode);
    let customAreas = queues.customAreas[this.customArea];
    this.isPaymentDatafono = customAreas.showButtonPaymentDataphone;
    this.terminal = customAreas.terminal;
  },

  destroyed() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.intervalFetch) {
      clearInterval(this.intervalFetch);
    }

    if (this.interPays) {
      clearInterval(this.interPays);
    }

    // clear data
    this.clear();
  },

  watch: {
    respPayments(r){
      let resp_s = r.split(',')[1];

      // Sucess
      if(resp_s == 'Msj:00'){
        this.sucessPay = true;
        this.failPay = false;
        this.cancelPay = false;

        // Sync pays
        this.syncPaymentsFSFB({
          "payment_date": moment().format('YYYY-MM-DD'),
          "pac_num": String(this.currentAppointment.pac_num),
          "form_number": this.currentAppointment.number_form,
          "cups": this.currentAppointment.attention.code,
          "transaction_identifier": String(this.terminal),
          "transaction_amount": String(this.currentAppointment.cost_appoinment),
          "pay_patient": "S",
          "tdoc": this.currentAppointment.tdoc.code,
          "doc": this.currentAppointment.doc,
          "name": this.currentUser.names,
          "last_name": this.currentUser.last_name,
          "mother_last_name": this.currentUser.mother_last_name,
          "email": this.currentUser.email,
          "phone": this.currentUser.phone
        });

        setTimeout(() => {
          this.clearMessagePays();
          this.respFromPays = null;
          this.setAppointment();
        }, 5000);
        
      }

      // Fail
      if(resp_s == 'Msj:XX'){
        this.failPay = true;
        this.cancelPay = false;
        this.sucessPay = false;

        // Stop check Payments
        if (this.interPays) clearInterval(this.interPays);

        // cancel transaction
        this.cancelPayment(this.respFromPays);

        // Close modal only
        setTimeout(() => {
          this.clearMessagePays();
          this.clear();
          this.sucessPay = false;
          this.failPay = false;
          this.cancelPay = false;
          this.respFromPays = null;
          this.pending = false;
          this.inPayment = false;
          this.$emit('startTimer');
          this.$bvModal.hide("pays-modal-virtualrowFSFB");
        }, 5000);

      }

      // pending
      if(resp_s == 'Msj:'){
        this.pending = true;
      }

      if(resp_s == 'Msj:00' || resp_s == 'Msj:XX'){

        this.loading = false;
        this.saveLogsPayments({
          transaction: 'N/A',
          terminal: this.terminal,
          atril_area: this.customArea,
          documento: this.currentUser.doc,
          response: r,
          action: 'Respuesta de la terminal',
          data: this.dataPayment,
        });
        // Stop internal timer for cancel pays
        this.$refs.ProgressCounter.stopProgress();

      }
    }
  },

  methods: {
    ...mapActions({
      sendMessageForPay: "virtualrowFSFB/sendMessageForPay",
      checkMessageStatus: "virtualrowFSFB/checkMessageStatus",
      removeTimer: "removeTimer",
      setTimer: "setTimer",
      goToView: "virtualrowFSFB/goToView",
      sendCrediPay: "virtualrowFSFB/sendCrediPay",
      checkCrediPay: "virtualrowFSFB/checkCrediPay",
      cancelPayment: "virtualrowFSFB/cancelPayment",
      setCitaCM: "virtualrowFSFB/setCitaCM",
      clearMessagePays:  "virtualrowFSFB/clearMessagePays",
      syncPaymentsFSFB: "virtualrowFSFB/syncPaymentsFSFB",
      saveLogsPayments: "virtualrowFSFB/saveLogsPayments",
      clear: "virtualrowFSFB/clear",
    }),

    async openTerminalPay() {
      if (this.isAtril) await this.setTimer();
      this.$bvModal.show("pays-modal-virtualrowFSFB");
      this.$emit('restartTimerPayents');
    },

    // Pay button from modal
    makePay() {
      this.loading = true;
      this.inPayment = true;

      // wait one second
      setTimeout(async () => {
        this.$emit('stopTimerPayments');
        // this.idTransaction = Math.floor(Math.random() * 9999999999);
        this.respFromPays = await this.sendCrediPay(this.terminal);

        // Chek pay status
        clearInterval(this.interPays);
        this.interPays = setInterval(() => {
          this.checkCrediPay(this.respFromPays);
        }, 5000);
      }, 1000);

    },

    async setAppointment() {
      if (this.isAtril) await this.removeTimer();
      await this.setCitaCM({
        ...this.currentAppointment,
        appoinment_url_paid: null,
        cost_appoinment: 0
      });
      if (this.isAtril) await this.setTimer();
    },

    closeModal() {
      
      if(this.inPayment){
        this.cancelPayAction();
        // Redirect to QR when fail
        setTimeout(() => {
          this.clearMessagePays();
          this.respFromPays = null;
          this.goToView({ view: "QR" });
        }, 5000);
      } else {
        this.$emit('startTimer');
        this.$bvModal.hide("pays-modal-virtualrowFSFB");
      } 
    },

    cancelPayAction(){
      this.cancelPayment(this.respFromPays);
      this.cancelPay = true;

      // stop check payment status
      clearInterval(this.interPays);

      this.saveLogsPayments({
        transaction: 'N/A',
        terminal: this.terminal,
        atril_area: this.customArea,
        documento: this.currentUser.doc,
        response: this.respPayments,
        action: 'Cancelación de transacción',
        data: this.dataPayment,
      })

    },

  }
};
</script>

<style lang="scss" scoped>
.SelectText {
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 45px;
  color: #283645;
}

.fonterH3 {
  color: #59616a;
}

.cardPayment {
  width: 584px;
  height: 616px;
  box-shadow: 0px 5.77255px 11.5451px rgba(21, 21, 21, 0.08);
  background: #ffffff;
  border-radius: 20px;
}

.cards {
  max-width: 1792px;
}

.text-card,
.text-card-others {
  width: 432px;
  margin: auto;
  margin-top: 60px;
  font-style: normal;
  font-weight: 300;
  font-size: 21px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}

.text-card-others {
  margin-top: 40px;
}

.qrCode {
  width: 189px;
  max-width: 189px;
  position: absolute;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%);
}

.payment_datafono {
  margin: auto;
  display: block;
  margin-top: 96px;
}

.buttonService,
.buttonServiceModal {
  font-family: "Gilroy-Light";
  width: 100%;
  max-width: 476px;
  margin: auto;
  margin-top: 50px;
  display: block;
  height: 89px;
  padding: 1.5rem 2rem;
  background-color: #009dac;
  border-radius: 20px;
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  line-height: 45px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.25));
}

.buttonServiceModal {
  padding: 24px 32px;
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 34px;
  max-width: 350px;
  margin: 0;
}

.containerBox {
  max-width: 550px;
  margin: auto;
  text-align: center;
}

.isPaymentDatafono {
  max-width: 1318px;
}

.canceledbtn {
  background-color: #ffffff;
  color: #283645;
}

.fonterCurrentUser {
  max-width: 476px;
  margin: auto;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
  color: #788591;
}

// .lds-dual-ring {
//   position: absolute;
//   left: 50%;
//   top: -12px;
//   transform: translate(-50%, -50%);
// }

// .lds-dual-ring::after {
//   width: 50px;
//   height: 50px;
//   border: 6px solid #ffffff;
//   border-color: #ffffff transparent #ffffff transparent;
// }
</style>
